import useDeviceDetect from "../hooks/useDeviceDetect";
import Accordion from "./al_components/ALAccordion";

import "./faqLuxe/styles.scss";

function FaqLuxe() {
  const { isMobile } = useDeviceDetect();
  return (
    <div>
      <h2 className="luxe_faq_title h2 h2--bold h2--uc">
        {isMobile ? "FAQ" : "Frequently Asked Questions"}
      </h2>
      <div>
        <Accordion title="What is AL Luxe?" isOpen>
          <div className="luxe_faq_answer subtext">
            <p>
              AL Luxe is Ana Luisa's exclusive membership program. This subscription-based
              membership offers total shopping freedom and a long list of perks – buildable store
              credit, additional discounts, and priority customer support – all for $39.99 a month
              (plus tax).
            </p>
            <p>
              Becoming a member is simple! You'll see the option to sign up from our shopping cart,
              along with a discount that comes with the exclusive membership. If you don't want to
              sign up for the membership, you can select "Guest" from your cart. The prices will
              update automatically before you place your order.
            </p>
            <p>
              Once your order is confirmed, you'll be charged a one-time trial fee of $1.95 (plus
              tax) for your first month of membership. After the first month, you'll be charged
              $39.99 (plus tax). This fee converts to credits you can use whenever you like. Pause,
              skip, or cancel your membership at any time – it really is that easy!
            </p>
          </div>
        </Accordion>
        <Accordion title="What is included in my AL Luxe membership?">
          <div className="luxe_faq_answer subtext">
            <b>Discount Perks</b>
            <p>
              - Buy More, Save More: Receive an additional 5% off during our site-wide promotions
            </p>
            <p>
              - Receive 5% Off Solid Gold Products: Exclusive to AL Luxe members (this the only way
              to save on our fine jewelry collection!)
            </p>
            <p>- Free Shipping: All your orders ship free (yes, all of them)</p>
            <br />
            <b>Sale Perks</b>
            <p>
              - Early Access: Receive notifications about our sales, and get the chance to shop
              before anyone else
            </p>
            <p>- Members-Only Sales: More sales throughout the year, exclusively catered to you</p>
            <p>- $$$ Off: Use your credit to unlock money off future purchases</p>
            <p>
              - Priority Customer Support: Need help with your order? Contact our team by email or
              live chat. We will provide a prompt, personalized experience
            </p>
          </div>
        </Accordion>
        <Accordion title="Does AL Luxe have a monthly fee?">
          <div className="luxe_faq_answer subtext">
            <p>
              Yes! For your first month of membership, you will be billed a one-time trial fee of
              $1.95 (plus tax) when you sign up. After that, you will be charged $39.99 (plus tax)
              on a monthly basis. Your available credit will be updated automatically, and you can
              use your credit to make purchases whenever you like.
            </p>
            <br />
            <p>
              <b>Will I receive any email confirmations as a member?</b>
            </p>
            <p>
              When you sign up for AL Luxe, we'll send an email to confirm the trial fee and welcome
              you to the program. We'll also give you a heads up before we charge you for your
              membership fees, and send over a confirmation once those fees are processed.
            </p>
            <p>
              If you signed up for AL Luxe by mistake, please contact our team at&nbsp;
              <a href="mailto:love@analuisa.com">
                <b>
                  <u>love@analuisa.com</u>
                </b>
              </a>
              . Refund requests must be made within 30 days of the charge. Trial fees are
              non-refundable.
            </p>
          </div>
        </Accordion>
        <Accordion title="Do I have to shop every month as a member of AL Luxe?">
          <div className="luxe_faq_answer subtext">
            <p>
              Absolutely not! The AL Luxe membership is flexible, so you don't need to make a
              purchase every month to maintain your member status. Your credit doesn't expire, so
              feel free to spend or save it however you like.
            </p>
            <p>
              You'll see the option to sign up from our shopping cart, along with the exclusive
              discount that comes with the membership. If you don't want to sign up for the
              membership, you can select "Guest" from your cart. The prices will update
              automatically before you place your order.
            </p>
          </div>
        </Accordion>
        <Accordion title="I'd like to cancel my membership.">
          <div className="luxe_faq_answer subtext">
            <p>
              We’ll miss you, but we understand some things don’t last forever. To cancel your AL
              Luxe membership, follow these steps.
            </p>
            <p>1. Log into your account.</p>
            <p>
              2. Click the button labeled &quot;Cancel Membership&quot; listed underneath your
              personal details.
            </p>
            <p>
              3. A pop-up will appear on the page. Follow the prompts on the screen to tell us a
              little bit about why you are ending your membership and finalize the cancellation.
            </p>
            <p>
              In addition to canceling, you have the ability to pause your membership at any time
              for up to three months. The steps to pause your membership are the same as canceling
              (You will see the option for both in the pop-up). If you decide to pause your
              membership, your membership will automatically renew at the end of the pause period.
            </p>
          </div>
        </Accordion>
        <Accordion title="How do I redeem my credit?">
          <div className="luxe_faq_answer subtext">
            <p>
              As an AL Luxe member, the fee you pay each month as a member becomes credit you can
              use on future purchases. This credit does not expire, so you can save it or spend it
              however you like. You can view your total credit balance at any time by logging into
              your account.
            </p>
            <p>
              To use your credit, simply log into your account and proceed to the checkout page.
              From here, you'll see a section that shows the credit you have available. You can
              change this to any amount within your balance, and apply the credit to your order by
              clicking the "Apply" button. You'll see the page refresh to update the total. Then,
              you can continue to follow all the usual steps to finish placing your order.
            </p>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default FaqLuxe;
